import { gql, useQuery } from '@apollo/client';
import { exists } from 'typeDeclarations/typeGuards';
import { BillingProfileNode, BudgetNode, CampaignNode } from 'typeDeclarations/graphql/nodes';
import { useDefaultOnError } from 'hooks/useDefaultOnError';

type CampaignBillingProfileInfoQueryVariables = {
  campaignUglyId?: string;
  campaignPrettyId?: string;
};

interface CampaignBillingProfileInfoQueryData {
  campaign:
    | null
    | (Pick<CampaignNode, 'id' | 'isDraft' | 'modified' | 'minimumTopupAmount'> & {
        budget:
          | null
          | (Pick<BudgetNode, 'id' | 'modified' | 'currency' | 'dailyAmount'> & {
              billingProfile: null | Pick<BillingProfileNode, 'id' | 'balance' | 'modified' | 'hasCreditLine'>;
            });
      });
}

const CAMPAIGN_BILLING_PROFILE_INFO_QUERY = gql`
  query campaignBillingProfileInfoQuery($campaignUglyId: ID, $campaignPrettyId: String) {
    campaign(id: $campaignUglyId, _id: $campaignPrettyId) {
      id
      isDraft
      modified
      minimumTopupAmount
      budget {
        id
        modified
        currency
        dailyAmount
        billingProfile {
          id
          balance
          modified
          hasCreditLine
        }
      }
    }
  }
`;

export type BudgetIssueID = 'insufficient-funds-for-campaign';
export type BudgetIssue = {
  balance: number;
  currency: string;
  id: BudgetIssueID;
  minimumAmount: number;
};

export function useCampaignBudgetWalletIssues({
  campaignUglyId,
  campaignPrettyId,
}: CampaignBillingProfileInfoQueryVariables): null | BudgetIssue {
  const onError = useDefaultOnError();
  const variables: CampaignBillingProfileInfoQueryVariables = {};

  if (campaignUglyId) {
    variables.campaignUglyId = campaignUglyId;
  } else {
    variables.campaignPrettyId = campaignPrettyId;
  }

  const { data, error, loading } = useQuery<
    CampaignBillingProfileInfoQueryData,
    CampaignBillingProfileInfoQueryVariables
  >(CAMPAIGN_BILLING_PROFILE_INFO_QUERY, {
    variables,
    fetchPolicy: 'network-only',
    onError: (err) => {
      // this error is expected when there is no bugdet yet, so we don't want to show it to the user
      if (err.graphQLErrors[0]?.extensions?.error_code !== 'cannot_calculate_min_top_amount_error') {
        onError(err);
      }
    },
  });

  if (loading || error || !data) return null;

  const { campaign } = data;

  if (!campaign) {
    window.location.href = '/not-found';
    return null;
  }

  const { budget, minimumTopupAmount, isDraft } = campaign;
  const billingProfile = budget?.billingProfile;

  if (!exists(budget) || !exists(billingProfile)) return null;

  const { dailyAmount, currency } = budget;
  const { hasCreditLine, balance } = billingProfile;

  if (hasCreditLine || !exists(balance)) return null;

  const balanceAmount = Number(balance);
  const dailyCost = Number(dailyAmount);

  if (balanceAmount >= Number(minimumTopupAmount)) return null;

  //do not show budget top up alert if campaign is running
  if (!isDraft && balanceAmount > dailyCost) return null;

  return {
    currency,
    balance: balanceAmount,
    minimumAmount: Number(minimumTopupAmount),
    id: 'insufficient-funds-for-campaign',
  };
}
