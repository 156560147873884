import { gql, useMutation, useQuery } from '@apollo/client';
import { createFilterOptions, Grid, ListItem, TextField, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { ChangeEvent, FocusEvent, FunctionComponent, useContext, useMemo } from 'react';

import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { logError } from 'utils/logging';
import { LocaleContext } from 'i18n/LocaleContext';
import { fetchAuth } from 'utils/fetchAuth';
import { ValidationResponse } from 'typeDeclarations/endpoints';
import { isString } from 'typeDeclarations/typeGuards';
import { BillingProfileNode, TeamNode } from 'typeDeclarations/graphql/nodes';
import { DefaultAutocomplete } from 'shared/DefaultAutocomplete';
import { LoadingBlock } from 'shared/LoadingBlock';
import { ErrorMessage } from 'shared/ErrorMessage';
import {
  VAT_FORM_ALL_COUNTRIES_FRAGMENT,
  VAT_FORM_BILLING_PROFILE_FRAGMENT,
  VatFormAllCountriesData,
  VatFormBillingProfileData,
} from './fragments';
import { useDefaultOnError } from 'hooks/useDefaultOnError';
import { ProgressButton } from 'shared/ProgressButtons/ProgressButton';

// FIXME: can be shared
async function asyncValidateField(endpoint: string, value: string) {
  const res = await fetchAuth(endpoint, { body: value });
  const { valid } = (await res.json()) as ValidationResponse;

  return !valid;
}

type VatModalFormData = VatFormAllCountriesData & {
  sessionTeam: Pick<TeamNode, 'id' | 'modified'> & {
    billingProfile: VatFormBillingProfileData & Pick<BillingProfileNode, 'id' | 'modified'>;
  };
};

const VAT_MODAL_CONTENT_QUERY = gql`
  query vatModalFormQuery {
    sessionTeam {
      id
      modified
      billingProfile {
        id
        modified
        ...vatFormBillingProfileFragment
      }
    }
    ...vatFormAllCountriesFragment
  }
  ${VAT_FORM_ALL_COUNTRIES_FRAGMENT}
  ${VAT_FORM_BILLING_PROFILE_FRAGMENT}
`;

interface UpdateBillingProfileVatData {
  updateBillingProfile: {
    billingProfile: VatFormBillingProfileData & Pick<BillingProfileNode, '__typename' | 'id' | 'modified'>;
  };
}

interface UpdateBillingProfileVatVariables {
  input: {
    country: string | null;
    vatNumber?: string | null;
    hasConfirmedVatNumber: boolean;
  };
}

const UPDATE_BILLING_PROFILE_VAT = gql`
  mutation updateBillingProfileVat($input: UpdateBillingProfileInput!) {
    updateBillingProfile(input: $input) {
      billingProfile {
        id
        modified
        topupAmountLimits {
          minimumAmountWithVat
          maximumAmountWithVat
        }
        ...vatFormBillingProfileFragment
      }
    }
  }
  ${VAT_FORM_BILLING_PROFILE_FRAGMENT}
`;

interface CountryItem {
  countryName: string;
  countryPrettyId: string;
}

interface VatFormValues {
  country: string;
  vatNumber: string;
}

export const VatForm: FunctionComponent = () => {
  const { formatMessage } = useExtendedIntl();
  const onError = useDefaultOnError();
  const { appLocale } = useContext(LocaleContext);
  const intlCollator = useMemo(() => new Intl.Collator(appLocale), [appLocale]);

  const [updateBillingProfileVat] = useMutation<UpdateBillingProfileVatData, UpdateBillingProfileVatVariables>(
    UPDATE_BILLING_PROFILE_VAT,
    {
      onError: (err) => onError(err),
      refetchQueries: ['walletVatQuery'],
    },
  );

  const { data, error, loading } = useQuery<VatModalFormData>(VAT_MODAL_CONTENT_QUERY, { onError });

  if (loading) return <LoadingBlock />;

  if (error || !data) return <ErrorMessage />;

  const validateISOCode = (countryCode: string) => {
    // We can just check if a country has been selected
    if (!countryCode) {
      return formatMessage({ id: 'shared.invalid-country' });
    }

    return undefined;
  };

  const validateVatNumber = async (values: VatFormValues) => {
    const { country, vatNumber } = values;

    if (!vatNumber) return;

    const endpoint = '/api/validate/billing_profile/vat_number/';

    try {
      const hasVatNumberError = await asyncValidateField(endpoint, JSON.stringify({ vatNumber, countryId: country }));

      return hasVatNumberError ? formatMessage({ id: 'shared.vat-number-error' }, { country }) : undefined;
    } catch (err) {
      if (isString(err)) {
        logError(new Error(err));
      }
      return {
        vatNumber: formatMessage({ id: 'shared.default-error-message' }),
      };
    }
  };

  const onFormikSubmit = async (values: VatFormValues) => {
    const input = {
      ...values,
      hasConfirmedVatNumber: true,
    };

    return updateBillingProfileVat({ variables: { input } });
  };

  const { country, vatNumber } = data.sessionTeam.billingProfile;

  const countryItems: CountryItem[] = [];

  data.allVatModalCountries.edges.forEach(({ node: countryNode }) => {
    if (countryNode.selectable || countryNode._id === country) {
      countryItems.push({
        countryPrettyId: countryNode._id,
        countryName: formatMessage({ id: `country.${countryNode._id}` }),
      });
    }
  });

  countryItems.sort((itemA, itemB) => intlCollator.compare(itemA.countryName, itemB.countryName));

  const initialValues = {
    country: country ?? '',
    vatNumber: vatNumber ?? '',
  };

  function renderCountryItemOption({ countryName, countryPrettyId }: CountryItem) {
    return `${countryPrettyId} - ${countryName}`;
  }

  return (
    <Formik<VatFormValues>
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onFormikSubmit}
      initialValues={initialValues}
    >
      {({
        values,
        errors,
        setErrors,
        handleSubmit,
        isSubmitting,
        validateField,
        handleChange: defaultHandleChange,
        setFieldValue: defaultSetFieldValue,
      }) => {
        const onAnyFieldBlur = (event: FocusEvent<HTMLInputElement>) => {
          validateField(event.currentTarget.name);

          /**
           * Everytime the country changes, the vat number must also be re-validated
           * It's a necessary cross-check
           */
          if (event.currentTarget.name === 'country') {
            validateField('vatNumber');
          }
        };

        const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
          defaultHandleChange(e);
          setErrors({ ...errors, [e.target.name]: undefined });
        };

        const setFieldValue = (field: string, value: unknown, shouldValidate?: boolean | undefined) => {
          defaultSetFieldValue(field, value, shouldValidate);
          setErrors({ ...errors, [field]: undefined });
        };

        // Country getter
        function getSelectedCountryItem() {
          return !values.country
            ? null
            : (countryItems.find((countryItem) => values.country === countryItem.countryPrettyId) ?? null);
        }

        function onCountryChange(_: ChangeEvent<unknown>, selectedCountryItem: CountryItem | null) {
          const countryPrettyId = selectedCountryItem?.countryPrettyId ?? '';
          setFieldValue('country', countryPrettyId);
        }

        return (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item container spacing={2}>
                <Grid item xs={6}>
                  <Field name="country" validate={validateISOCode}>
                    {() => (
                      <DefaultAutocomplete
                        options={countryItems}
                        disabled={isSubmitting}
                        onChange={onCountryChange}
                        value={getSelectedCountryItem()}
                        getOptionLabel={(countryItem) => countryItem.countryPrettyId}
                        filterOptions={createFilterOptions({ stringify: renderCountryItemOption })}
                        renderOption={(props, { countryName, countryPrettyId }) => (
                          <ListItem {...props} key={countryPrettyId}>
                            {`${countryPrettyId} - ${countryName}`}
                          </ListItem>
                        )}
                        textFieldProps={{
                          name: 'country',
                          placeholder: formatMessage({ id: 'shared.dash-placeholder' }),
                          onBlur: onAnyFieldBlur,
                          error: Boolean(errors.country),
                          helperText: errors.country || formatMessage({ id: 'shared.iso-code-label' }),
                        }}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field name="vatNumber" validate={() => validateVatNumber(values)}>
                    {() => (
                      <TextField
                        fullWidth
                        margin="normal"
                        name="vatNumber"
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={onAnyFieldBlur}
                        disabled={isSubmitting}
                        value={values.vatNumber}
                        error={Boolean(errors.vatNumber || errors.country)}
                        placeholder={formatMessage({ id: 'shared.vat-number-input-placeholder' })}
                        helperText={errors.vatNumber || formatMessage({ id: 'shared.vat-number' })}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Grid item container alignItems="center" justifyContent="space-between" spacing={1}>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" color="textSecondary">
                    {formatMessage({ id: 'vat-modal-content.subtitle' })}
                  </Typography>
                </Grid>
                <Grid item container xs={12} md={6} justifyContent="flex-end">
                  <ProgressButton type="submit" variant="outlined" loading={isSubmitting}>
                    {formatMessage({ id: 'vat-modal-content.confirm-button' })}
                  </ProgressButton>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
